<template>
  <span
    class="dropdown"
    :class="{ show: isDropdownOpen }"
    v-click-outside="close"
  >
    <button
      @click="isDropdownOpen = !isDropdownOpen"
      :class="classes"
      type="button"
      :aria-expanded="isDropdownOpen ? 'true' : 'false'"
    >
      <slot name="activator" />
    </button>
    <div
      class="dropdown-menu animated fadeInUp"
      :class="{ show: isDropdownOpen }"
    >
      <slot />
    </div>
  </span>
</template>

<script>
import Vue from "vue";
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  }
});

export default {
  props: ["classes"],
  data() {
    return {
      isDropdownOpen: false
    };
  },
  methods: {
    close() {
      this.isDropdownOpen = false;
    }
  },
  watch: {
    $route() {
      this.isDropdownOpen = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";
@import "../../styles/utils.scss";
button {
  outline: none;
  background: transparent;
  // border: transparent;
}
.dropdown {
  position: relative !important;
  display: inline-block;
  .dropdown-menu {
    padding: 0;
    left: 0 !important;
    float: none !important;
    margin-top: 30px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    &::before {
      content: "";
      position: absolute;
      top: -10px;
      color: $white;
      display: inline-block;
      margin-left: 25px;
      border-bottom: 10px solid;
      border-right: 10px solid transparent;
      border-top: 0;
      border-left: 10px solid transparent;
    }
    a {
      cursor: pointer;
      padding: 8px 12px;
      color: $gray-500;
      & {
        @include transition_s;
      }
      &:hover,
      &:focus,
      &:active {
        color: $secondary;
        background-color: transparent;
      }
    }
    .dropdown-menu__items {
      padding: 25px 45px 25px 15px;
    }
    .dropdown-menu_details {
      min-width: 325px;
    }
  }
}
</style>
