<template>
  <header id="Header" class="bg-secondary d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 text-sm-left text-center">
          <router-link to="/dashboard" title="logo">
            <img
              src="/assets/imgs/logo.png"
              alt="logo"
              title="logo"
              height="60"
              class="mr-2 mr-lg-4"
            />
          </router-link>
          <app-dropdown
            classes="btn btn-outline-light border op--light dropdown-toggle"
          >
            <template slot="activator">{{ $t("mypanel") }}</template>
            <div class="dropdown-menu__items">
              <router-link class="dropdown-item" to="/dashboard">
                <i class="icon-user mr-2 size--s18"></i>
                Meus cursos
              </router-link>
              <router-link class="dropdown-item" to="/catalog">
                <i class="icon-book mr-2 size--s18"></i>
                Cursos
              </router-link>
              <router-link class="dropdown-item" to="/notices">
                <i class="icon-adverts mr-2 size--s18"></i>
                Destaques
              </router-link>
              <!-- <router-link class="dropdown-item" to="/gamification">
                <i class="icon-winner mr-2 size--s18"></i>
                {{ $t("gamification") }}
              </router-link> -->
            </div>
          </app-dropdown>
        </div>
        <div class="col-sm-6 col-12 text-sm-right text-center">
          <div class="d-flex align-items-center justify-content-end">
            <p class="mr-3">
              <span class="text-primary">{{ user.Name }}</span>
            </p>
            <!-- <p class="mr-3">
              <button
                class="btn btn-link p-0 mr-1"
                :class="{ 'text-white': currentLang === 'pt' }"
                @click.prevent="changeLanguage('pt')"
              >
                PT
              </button>
              <button
                class="btn btn-link p-0"
                :class="{ 'text-white': currentLang === 'en' }"
                @click.prevent="changeLanguage('en')"
              >
                EN
              </button>
            </p>-->
            <div class="drop-profile">
              <app-dropdown
                classes="rounded--s50 p-0 border op--light d-flex align-items-center"
              >
                <template slot="activator">
                  <span class="profile circle">
                    <img
                      :src="
                        GetRelativePath(
                          user.Photo ? user.Photo : '/Files/profile.png',
                          'w=45&h=45&mode=crop'
                        )
                      "
                      alt="foto user"
                      title="foto user"
                      class="img-circle rounded img-fluid"
                    />
                  </span>
                  <i class="icon-arrow-down px-3"></i>
                </template>
                <div class="dropdown-menu_details">
                  <div class="d-flex align-items-center bg--primary p-4">
                    <span class="circle">
                      <img
                        :src="
                          `${GetRelativePath(
                            user.Photo ? user.Photo : '/Files/profile.png',
                            'w=90&h=90&mode=crop'
                          )}`
                        "
                        alt="foto user"
                        title="foto user"
                        class="img-circle rounded img-fluid"
                      />
                    </span>
                    <div class="ml-3">
                      <h4 class="font--base text-white font-weight-normal">
                        {{ user.Name }}
                      </h4>
                      <p class="size--s12" v-if="user.Email">
                        {{ user.Email }}
                      </p>
                      <p class="size--s12">{{ user.Code }}</p>
                    </div>
                  </div>
                  <div class="dropdown-menu__items">
                    <router-link
                      v-for="menu in userMenus"
                      :key="menu.title"
                      class="dropdown-item"
                      :to="menu.route"
                    >
                      <i class="size--s18 mr-2" :class="menu.icon"></i>
                      {{ menu.title }}
                    </router-link>
                    <router-link
                      v-if="isTeamLeader"
                      to="/myaccount/team"
                      class="dropdown-item"
                    >
                      <i class="size--s18 mr-2 icon-users"></i>
                      Equipa
                    </router-link>
                    <a
                      v-if="isAdmin && backendUrl"
                      :href="backendUrl"
                      target="_blank"
                      class="dropdown-item"
                    >
                      <i class="size--s18 mr-2 icon-user"></i>
                      Administração
                    </a>
                    <a
                      href="#"
                      @click.prevent="logout"
                      class="btn btn-outline-light px-4 ml-2"
                      >{{ $t("signout") }}</a
                    >
                  </div>
                </div>
              </app-dropdown>
            </div>
            <router-link
              class="rounded-icon text-decoration-none btn btn-link p-0 border border-light ml-3"
              to="/intro"
            >
              <i class="icon-help text-light" style="font-size:27px;"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as types from "@/store/types";
import { API_URL } from "@/settings";
import DropdownComponent from "@/components/helpers/dropdown";
import UrlBuilder from "@/utilities/url-builder";

export default {
  components: {
    "app-dropdown": DropdownComponent
  },
  data() {
    return {
      backendUrl: API_URL,
      isFullScreen: false
    };
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    currentLang() {
      return this.$i18n.locale;
    },
    isAdmin() {
      return this.user.IdUserRole > 1;
    },
    isTeamLeader() {
      return this.user.IdUserGroup && this.user.IdUserGroup == 3;
    },
    userMenus() {
      return [
        {
          title: this.$t("formativePath"),
          route: "/myaccount",
          icon: "icon-catalog-challenge"
        },
        {
          title: this.$t("inscriptions"),
          route: "/myaccount/inscriptions",
          icon: "icon-book"
        },
        {
          title: this.$t("calendar"),
          route: "/myaccount/calendar",
          icon: "icon-calendar"
        },
        {
          title: this.$t("history"),
          route: "/myaccount/history",
          icon: "icon-user-info"
        },
        {
          title: this.$t("documents"),
          route: "/myaccount/downloads",
          icon: "icon-download-file"
        },
        {
          title: this.$t("profile"),
          route: "/myaccount/profile",
          icon: "icon-user"
        },
        {
          title: "Alterar password",
          route: "/myaccount/security",
          icon: "icon-lock"
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      logout: types.USER_LOGOUT
    }),
    GetRelativePath(path, query) {
      return `${UrlBuilder.GetPath(path)}${query ? "?" + query : ""}`;
    },
    setFullscreen() {
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        document.getElementById("WebPage").requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";

.circle {
  border-radius: 50%;
  overflow: hidden;
}

.dropdown-menu__items {
  a:hover {
    color: $primary !important;
  }
}
</style>
