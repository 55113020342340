import * as types from "./types";

export default {
  [types.MUTATE_BASE]: (state, payload) => {
    state.User = payload.User;
    state.Positions = payload.UserPositions;
    state.Notices = payload.Notices;
    state.Favourites = payload.Favourites;
    state.Subscriptions = payload.Subscriptions;
    state.ProgressionProfile = payload.ProgressionProfile;
    state.Gamification = payload.Gamification;
    state.IsFirstLogin = payload.IsFirstLogin;
  },
  [types.MUTATE_USER]: (state, payload) => {
    state.User = payload;
  },
  [types.MUTATE_USER_POSITIONS]: (state, payload) => {
    state.Positions = payload;
  },
  [types.MUTATE_USER_NOTICES]: (state, payload) => {
    state.Notices = payload;
  },
  [types.MUTATE_SUBSCRIPTIONS]: (state, payload) => {
    state.Subscriptions = payload;
  },
  [types.MUTATE_PROGRESSION_PROFILE]: (state, payload) => {
    state.ProgressionProfile = payload;
  },
  [types.MUTATE_GAMIFICATION]: (state, payload) => {
    state.Gamification = payload;
  },
  [types.MUTATE_FAVOURITES]: (state, payload) => {
    state.Favourites = payload;
  },
  [types.MUTATE_CLEAR_STORE]: state => {
    state.User = null;
    state.Notices = null;
    state.Subscriptions = null;
    state.Favourites = null;
    state.Positions = null;
    state.ProgressionProfile = null;
    state.Gamification = null;
  }
};
