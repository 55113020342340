<template>
  <header id="HeaderMobile" class="bg-primary">
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top d-flex justify-content-between align-items-center"
    >
      <button
        class="navbar-toggler text-white"
        type="button"
        v-on:click="toggleMenu()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link class="navbar-brand mx-0" to="/">
        <img
          src="/assets/imgs/logo-white.png"
          alt="logo"
          title="logo"
          class="img-fluid"
        />
      </router-link>
      <button
        class="navbar-toggler text-white"
        type="button"
        v-on:click="toggleProfile()"
      >
        <img
          :src="
            `${GetRelativePath(
              user.Photo ? user.Photo : '/Files/profile.png',
              'w=35&h=35&mode=crop'
            )}`
          "
          alt="foto user"
          title="foto user"
          class="img-circle circle img-fluid"
        />
      </button>
      <!-- PROFILE DROPDOWN -->
      <div
        class="collapse navbar-collapse"
        id="navbarToggleProfile"
        v-bind:class="{ show: isProfileOpen }"
      >
        <div class="bg-primary p-2">
          <router-link
            v-for="menu in userMenus"
            :key="menu.title"
            class="dropdown-item text-white pl-2 pb-2"
            :to="menu.route"
          >
            <i class="size--s18 mr-2" :class="menu.icon"></i>
            {{ menu.title }}
          </router-link>
          <router-link
            v-if="isTeamLeader"
            to="/myaccount/team"
            class="dropdown-item text-white pl-2 pb-2"
          >
            <i class="size--s18 mr-2 icon-users"></i>
            Equipa
          </router-link>
          <a
            v-if="isAdmin && backendUrl != null"
            :href="backendUrl"
            target="_blank"
            class="dropdown-item text-white pl-2 pb-2"
          >
            <i class="size--s18 mr-2 icon-user"></i>
            Administração
          </a>
          <a
            href="#"
            @click.prevent="logout"
            class="btn btn-outline-light px-4 ml-2 my-4"
            >{{ this.$t("exit") }}</a
          >
        </div>
      </div>
      <!-- END PROFILE DROPDOWN -->
      <!-- MAIN DROPDOWN MENU -->
      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        v-bind:class="{ show: isOpen }"
      >
        <ul class="navbar-nav pl-3 pt-2">
          <li class="nav-item">
            <router-link class="nav-link" to="/">
              <i class="icon-user mr-2 size--s18"></i>
              {{ $t("myPanel") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/catalog">
              <i class="icon-book mr-2 size--s18"></i>
              {{ $t("courses") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/formation">
              <i class="icon-book mr-2 size--s18"></i> Catálogo
              Formação</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/notices">
              <i class="icon-adverts mr-2 size--s18"></i>
              {{ $t("ads") }}
            </router-link>
          </li>
          <li class="nav-item">
            <!-- <router-link class="nav-link" to="/gamification">
              <i class="icon-winner mr-2 size--s18"></i>
              {{ $t("gamification") }}
            </router-link> -->
          </li>
          <div class="search my-4">
            <input
              placeholder="pesquisa"
              type="text"
              v-model="searchQuery"
              @keyup.enter="onTyping"
            />
            <button type="button" class="btn btn-link text-decoration-none">
              <i class="icon-search"></i>
            </button>
          </div>
        </ul>
      </div>
      <!-- END MAIN DROPDOWN MENU -->
    </nav>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { API_URL } from "@/settings";
import * as types from "@/store/types";
import UrlBuilder from "@/utilities/url-builder";

export default {
  data() {
    return {
      backendUrl: API_URL,
      searchQuery: null,
      isOpen: false,
      isProfileOpen: false
    };
  },
  watch: {
    $route() {
      this.isOpen = false;
      this.isProfileOpen = false;
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    userMenus() {
      return [
        // {
        //   title: this.$t("formativePath"),
        //   route: "/myaccount",
        //   icon: "icon-catalog-challenge"
        // },
        {
          title: this.$t("inscriptions"),
          route: "/myaccount/inscriptions",
          icon: "icon-book"
        },
        // {
        //   title: this.$t("calendar"),
        //   route: "/myaccount/calendar",
        //   icon: "icon-calendar",
        // },
        {
          title: this.$t("history"),
          route: "/myaccount/history",
          icon: "icon-user-info"
        },
        // {
        //   title: this.$t("documents"),
        //   route: "/myaccount/downloads",
        //   icon: "icon-download-file"
        // },
        {
          title: this.$t("profile"),
          route: "/myaccount/profile",
          icon: "icon-user"
        },
        {
          title: "Alterar password",
          route: "/myaccount/security",
          icon: "icon-lock"
        }
      ];
    }
  },
  methods: {
    isAdmin() {
      return this.user.IdUserRole > 1;
    },
    isTeamLeader() {
      return this.user.IdUserGroup && this.user.IdUserGroup == 3;
    },
    onTyping() {
      this.isOpen = false;

      this.$router.push({
        name: "catalog",
        query: { Search: this.searchQuery }
      });
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
      this.isProfileOpen = false;
    },
    toggleProfile() {
      this.isProfileOpen = !this.isProfileOpen;
      this.isOpen = false;
    },
    ...mapActions({
      logout: types.USER_LOGOUT
    }),
    GetRelativePath(path, query) {
      return `${UrlBuilder.GetPath(path)}${query ? "?" + query : ""}`;
    },
    setFullscreen() {
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        document.getElementById("WebPage").requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  },
  created() {
    this.searchQuery = this.$route.query.Search;
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";
@import "../../styles/utils.scss";
.search {
  position: relative;
  input {
    color: #fff;
    background-color: transparent;
    border: 1px solid $light;
    border-radius: 25px;
    padding: 8px 25px;
    padding-left: 35px;
    font-size: 13px;
    outline: none;
    & {
      @include placeholder(darken($color: $white, $amount: 20%));
    }
  }
  button {
    position: absolute;
    left: -15px;
    color: darken($color: $white, $amount: 20%);
  }
}
.router-link-active {
  background: none !important;
}
.circle {
  border-radius: 50%;
  overflow: hidden;
}
.drop-profile {
  .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -10px;
    color: blue; //gray-400
    display: inline-block;
    margin-left: 25px;
    border-bottom: 10px solid;
    border-right: 10px solid transparent;
    border-top: 0;
    border-left: 10px solid transparent;
  }
}
</style>
