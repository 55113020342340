<template>
  <footer id="Footer" class="text-white" v-if="isVisible">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 text-sm-left text-center">
          <p>{{ year }} @ APDP Elearn | Portugal</p>
        </div>
        <div class="col-sm-6 col-12 text-sm-right text-center">
          <router-link to="/intro" class="help-link">
            Ajuda
            <i class="icon-help"></i>
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isVisible: true
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
  watch: {
    $route() {
      this.checkVisibility();
    }
  },
  methods: {
    checkVisibility() {
      this.isVisible = !(
        this.$route.path.startsWith("/courses/detail/") &&
        this.$route.path.indexOf("/content/") > 0
      );
    }
  },
  created() {
    this.checkVisibility();
  }
};
</script>

<style scoped lang="scss">
.help-link {
  color: #fff;
}
</style>
