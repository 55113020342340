export const messages = {
  pt: {
    comments: "Comentários | Dúvidas",
    commentsOpen: "Comentários | Questões | Dúvidas",
    nosubcriptions: "Não há cursos iniciados",
    teamLeader: "Líder de Equipa",
    toStart: "Por Iniciar",
    noContent: "Não existe conteúdo",
    "menu-formations": "Cursos",
    accessAccount: "Entrar na sua conta",
    accessCourse: "Aceder ao curso",
    actives: "Ativos",
    activity: "Atividade",
    activityTime: "Tempo de Atividade",
    ads: "Destaques",
    agents: "Agentes",
    all: "Todos",
    ambassadors: "Embaixadores",
    answersRequired: "É obrigatório responder a todas as perguntas",
    approved: "Aprovado",
    addionalFiles: "Ficheiros Auxiliares",
    archived: "Arquivados",
    areYouSure: "Tem a certeza?",
    at: "em",
    classes: "Turmas",
    attention: "Atenção",
    auxiliaryFiles: "Ficheiros Auxiliares",
    availableCourses: "Cursos disponiveis",
    bakeryAndPastryShopAutoControl: "01",
    bakeryPastryShop: "Padaria Pastelaria",
    bazar: "Bazar",
    blocked: "Bloqueado",
    butcher: "Talho",
    calendar: "Calendário",
    cancel: "Cancelar",
    catalogDownload: "Download do Catálogo",
    categories: "Categorias",
    certDownload: "Download de certificado",
    charcuterie: "Charcutaria",
    choosePhoto: "Escolha a foto",
    classRegist: "Inscrito na turma",
    classification: "Classificação",
    cleaningAndDisinfectionRules: "REGRAS DE LIMPEZA E DESINFECÇÃO",
    close: "Fechar",
    code: "Código",
    complementary: "Complementar",
    completed: "Finalizado",
    completedCourses: "Cursos concluídos",
    concluded: "Concluído",
    concludedCourse: "Curso concluído",
    concludedDate: "Data conclusão",
    concludedSteps: "etapas concluídas",
    confirm: "Confirmar",
    contentOrganization: "Organização de conteúdos",
    continue: "Continuar",
    continuousFormation: "Formação Contínua",
    course: "Curso",
    courseDetails: "Detalhes do curso",
    courses: "Cursos",
    currentPoints: "Pontos atuais",
    customerGuidanceAndSale: "ORIENTAÇÃO CLIENTE E VENDA (ATENDIMENTO)",
    dashboardTitle: "Aqui é onde tudo começa!",
    day: "Dia",
    description: "Descrição",
    documents: "Documentos",
    drivingLoadEquipment:
      "CONDUÇÃO E MANOBRA DE EQUIPAMENTOS DE CARGA E DESCARGA",
    duration: "Duração",
    eLearnPlataform: "Plataforma de e-Learning",
    editProfile: "Editar perfil",
    email: "Email",
    end: "Fim",
    endDate: "Data de fim",
    endOfCourse: "Fim do curso",
    error: "Erro",
    exemple: "Examplo",
    exit: "Sair",
    fishmonger: "Peixaria",
    foodHygieneAndSelfControl: "HIGIENE ALIMENTAR E AUTOCONTROLO EM PF",
    formationCatalog: "Catálogo de formação",
    formationsInProgress: "Cursos a decorrer",
    formativePath: "Percurso Formativo",
    freshProducts: "Produtos Frescos",
    fruitsAndVegetables: "Fruitas e Verduras",
    FavouriteFormations: "Favourits",
    gamification: "Gamification",
    gastronomy: "Gastronomia",
    gradeMeter:
      "N/A – Não se aplica; 1 – Muito fraco; 2 – Fraco; – Médio; 4 – Bom; 5 – Muito bom",
    greatConsumerProducts: "Produtos grande consumo",
    group: "Grupo",
    handlingWorkEquipment: "MANUSEIO DE EQUIPAMENTOS DE TRABALHO",
    help: "Ajuda",
    highlights: "Destaques",
    history: "Histórico",
    inProgress: "A decorrer",
    inactives: "Inativos",
    information: "Informação",
    informations: "Informações",
    infos: "Informações",
    initialTrainingSirius: "SIRIUS - FORMAÇÃO INICIAL",
    initiatedCourses: "Cursos iniciados",
    inscriptions: "Meus Cursos",
    integrationInNewFunctionAreaBakeryOrPastryShop:
      "INTEGRAÇÃO EM NOVA FUNÇÃO OU ÁREA PADARIA/PASTELARIA",
    integrationTraining: "Formação de Integração",
    introduction: "Introdução",
    invalidData: "Dados inválidos",
    level: "Nível",
    list: "Lista",
    listOfCourses: "Listagem de cursos",
    loading: "Carregando",
    locale: "Local",
    minimumRequired: "Mínimo obrigatório",
    minutes: "minutos",
    minutesRequired: "minutos",
    moduleDescription: "Descrição do Módulo",
    modules: "MÓDULOS",
    month: "Mês",
    months: "meses",
    moreOptions: "Cursos",
    morePopular: "Populares",
    myActivities: "Minhas atividades",
    myAuchan: "MY",
    myaccount: "Minha Conta",
    myList: "Favoritos",
    myPanel: "Meus cursos",
    myStore: "Minha conta",
    mylist: "Favoritos",
    mypanel: "Menu principal",
    MyCourses: "My Courses",
    name: "Nome",
    next: "Seguinte",
    nextlevel: "Próximo nível",
    nexts: "Seguintes",
    no: "Não",
    noActiveFormations: "Não existem cursos ativos",
    noCheckTestAgain: "Não, rever teste",
    noDocs: "Não existem documentos",
    noModules: "Não existem módulos",
    noNews: "Não existem destaques",
    noResults: "Não existem resultados",
    noTrainersAssigned: "Sem formadores atribuídos",
    notConclude: "Não Concluído",
    notStarted: "Não inciado",
    novities: "Novidades",
    objectives: "Objetivos",
    obligatorySatisfactionInq:
      "É obrigatório o preenchimento do inquérito de satisfação.",
    of: "de",
    oneToThree: "1 a 3",
    ongoing: "Em curso",
    photo: "Foto",
    pointsEarned: "Pontos acumulados",
    pointsForGamification: "Pontos para Gamification",
    presential: "presencial",
    previous: "Anteriores",
    profile: "Perfil",
    profileChanged: "Os seus dados foram alterados",
    progress: "progresso",
    realizationDate: "Data de realização",
    recents: "Recentes",
    recipients: "Destinatários",
    recover: "Recuperar",
    recoverPassword: "Recuperar password?",
    recoverYourPassword: "Recupere a sua password",
    redoTest: "Refazer teste",
    registDate: "Data de inscrição",
    registerCourse: "INICIAR",
    registrations: "Inscrições",
    remember: "Memorizar os meus dados",
    reproved: "Reprovado",
    resgisteredIn: "Inscrito em",
    result: "Resultado da avaliação",
    save: "Guardar",
    scheduledContent: "Conteúdo Programático",
    trainers:"Formadores",
    search: "Pesquisar",
    ForSafetyReasons:
      "Por razões de segurança é necessário efectuar a alteração da sua palavra-passe",
    searchCourse: "pesquisar cursos...",
    searchGroup: "pesquise por Grupo...",
    searchStore: "pesquise a Loja",
    selfControlInFoodSafety: "AUTOCONTROLO EM SEGURANÇA ALIMENTAR",
    serviceCertificationOnly:
      "CERTIFICAÇÃO DO SERVIÇO EM PF (APENAS PARA LOJAS CERTIFICADAS)",
    services: "Services",
    signin: "Entrar",
    signing: "Entrar",
    leaveYourComment:
      "As suas questões / dúvidas serão registadas e respondidas isoladamente por e-mail ou na sessão síncrona final. Consulte o calendário no menu individual.",
    signout: "Terminar sessão",
    specificFormation: "Formação Específica",
    startCourse: "Iniciar curso",
    startDate: "Data de Inicio",
    startin: "a realizar em",
    state: "Estado",
    store: "Loja",
    submit: "Submeter",
    success: "Sucesso",
    successLogin: "Bem-vindo à form@!",
    takesPlaceIn: "Realiza-se dia",
    team: "Equipa",
    technologicalEquipment: "Equipamento Tecnológico",
    terminated: "Concluído",
    textile: "Têxtil",
    thisMonth: "Este mês",
    threeToSix: "3 a 6",
    timeInMinutes: "Tempo em minutos",
    today: "Hoje",
    topStore: "Top loja",
    total: "Total",
    totalUsers: "Total de utilizadores da equipa",
    totalinscriptions: "Total Cursos",
    trade: "Comércio",
    trainers: "Formadores",
    trainingAction: "Ação de formação",
    transversalFormation: "Formação transversal",
    username: "Username",
    users: "Utilizadores",
    visualize: "Ver Curso",
    wait: "Aguarde",
    week: "Semana",
    welcome: "",
    with: "com",
    withoutGrade: "Sem nota atribuída",
    yes: "Sim",
    yesIConfirm: "Sim, confirmo",
    yourEmail: "O seu email",
    yourName: "O seu nome",
    waitingForTeacherScore: "Aguarda Avaliação de formador",
    zone: "Zona",
    AuthNotifications:
      "Consinto a utilização de dados pessoais para gestão da inscrição.",
    MarketingApproved:
      "Autorizo a utilização dos meus dados de email, telefone e morada para envio de newsletters, revistas, formações e outras informações institucionais da APDP.",
    noFiles: "Nao existem ficheiros auxiliar",
    attachments: "Anexos",
    lowestScore: "Insuficiente",
    lowerScore: "Suficiente",
    higherScore: "Bom",
    highestScore: "Muito Bom",
    password: "Palavra-passe",
    confirmPassword: "Confirmar palavra-passe",
    yellowTextWithoutFinalResult:
      "Pergunta de consolidação de conhecimentos adquiridos (não considerada para avaliação final)",
    yellowTextWithFinalResult:
      "Pergunta de Avaliação Sumativa (considerada para avaliação final)",
    AvaliationGrid: "Grelha de Avaliação",
    notConcludedCourse: "Utilizador precisa de concluir o curso primeiro",
    students: "Formandos",
    BeWelcome: "Seja bem-vindo",
    passwordNotMatch: "Password dont Match or are empty",
    passwordChanged: "Password Changed",
    TermsAndPolitics: "Politica de privacidade",
    BeforeTextTermsAndPolitics:
      "Para mais informações consulte a nossa Política de privacidade e "
  },
  en: {
    BeforeTextTermsAndPolitics:
      "Para mais informações consulte a nossa Política de privacidade e ",
    TermsAndPolitics: "Politica de privacidade,",
    AuthNotifications:
      "Consinto a utilização de dados pessoais para gestão da inscrição.",
    MarketingApproved:
      "Consinto a utilização a utilização de dados pessoais para divulgação e comunicação por parte da APDP.",
    BeWelcome: "Be Welcome",
    waitingForTeacherScore: "Awaiting Trainer Evaluation",
    notConcludedCourse: "User needs to finish the course first",
    attachments: "attachments",
    noFiles: "There are none auxiliar files",
    addionalFiles: "Auxiliar Files",
    nosubcriptions: "No initiated courses",
    accessAccount: "Access your account",
    accessCourse: "Access Course",
    actives: "Actives",
    activity: "Activity",
    leaveYourComment: "Leave your comment",
    activityTime: "Activity Time",
    ads: "Updates",
    agents: "Agents",
    all: "All",
    comments: "Comments",
    commentsOpen: "Comments | Questions",
    ambassadors: "Ambassadors",
    answersRequired: "Mandatory to answer all the answers",
    yellowTextWithoutFinalResult:
      "Consolidation of acquired knowledge question (not considered for final assessment) ",
    yellowTextWithFinalResult:
      "Summative Evaluation Question (considered for final evaluation)",
    archived: "Archived",
    areYouSure: "Are you sure?",
    at: "at",
    attention: "Attention",
    auxiliaryFiles: "Supplemental Files",
    availableCourses: "Available Courses",
    bakeryAndPastryShopAutoControl: "BAKERY AND PASTRY SHOP AUTOCONTROL",
    bakeryPastryShop: "Bakery Pastry Shop",
    bazar: "Bazar",
    blocked: "Blocked",
    butcher: "Butcher",
    calendar: "Calendar",
    cancel: "Cancel",
    catalogDownload: "Brochure Download",
    categories: "Categories",
    certDownload: "Download Certificate",
    charcuterie: "Charcuterie",
    choosePhoto: "Choose yout photo",
    classRegist: "Enrolled in class",
    classification: "Classification",
    cleaningAndDisinfectionRules: "CLEANING AND DISINFECTION RULES",
    close: "Close",
    code: "Code",
    complementary: "Complementary",
    completed: "Completed",
    completedCourses: "Completed Courses",
    concluded: "Concluded",
    concludedCourse: "Course concluded",
    concludedDate: "Conclusion date",
    concludedSteps: "concluded Stages",
    confirm: "Confirm",
    contentOrganization: "Content Organization",
    continue: "Continue",
    continuousFormation: "Continuous Training",
    course: "Course",
    courseDetails: "Course details",
    courses: "Courses",
    currentPoints: "Current Score",
    customerGuidanceAndSale: "CUSTOMER GUIDANCE AND SALES (CARE)",
    dashboardTitle: "This is where all begins!",
    students: "Students",
    day: "Day",
    description: "Description",
    documents: "Documents",
    drivingLoadEquipment:
      " DRIVING AND MANEUVER LOADING AND UNLOADING EQUIPMENT",
    duration: "lenght",
    eLearnPlataform: " E-learning Platform",
    editProfile: "Editar profile",
    email: "Email",
    end: "End",
    endDate: "End Date",
    endOfCourse: "End of Course",
    error: "Error",
    exemple: "Example",
    passwordChanged: "Password Changed",
    passwordNotMatch: "Password dont Match or are empty",
    exit: "Exit",
    fishmonger: "Fishmonger",
    foodHygieneAndSelfControl: "FOOD HYGIENE AND SELF-CONTROL IN PF",
    formationCatalog: "Training Brochure",
    formationsInProgress: "Training courses in progress",
    formativePath: "Training Path",
    freshProducts: "Fresh Products",
    fruitsAndVegetables: "Fruits and Vegetables",
    gamification: "Gamification",
    gastronomy: "Gastronomy",
    gradeMeter:
      "n. a. – Not applicable; 1 – Weak; 2 – Medium; 3 – Good; 4 – Excelent",
    greatConsumerProducts: "Mass Consumer Products",
    group: "Group",
    handlingWorkEquipment: "HANDLING WORK EQUIPMENT",
    highlights: "Highlights",
    history: "History",
    inProgress: "In Progress",
    inactives: "Inactives",
    information: "Information",
    informations: "Informations",
    infos: "Infos",
    initialTrainingSirius: "SIRIUS - INITIAL TRAINING",
    initiatedCourses: "Initiated Courses",
    inscriptions: "Inscriptions",
    integrationInNewFunctionAreaBakeryOrPastryShop:
      "INTEGRATION IN NEW FUNCTION OR AREA BAKERY/PASTRY SHOP",
    integrationTraining: "Integration Training",
    introduction: "Introduction",
    invalidData: "Invalid Data",
    level: "Level",
    list: "List",
    listOfCourses: "Courses List",
    loading: "Loading",
    noContent: "There is no content",
    locale: "Place",
    lowestScore: "Insuficient",
    lowerScore: "Suficient",
    higherScore: "Good",
    highestScore: "Very Good",
    minimumRequired: "Minimum lenght required",
    minutes: "minutes",
    minutesRequired: "Minutes required",
    moduleDescription: "Module description",
    modules: "MODULES",
    month: "Month",
    months: "months",
    moreOptions: "More Options",
    morePopular: "More popular",
    myActivities: "My Activities",
    myAuchan: "MY AUCHAN",
    myList: "My List",
    myPanel: "My Panel",
    myStore: "My Store",
    mylist: "My list",
    mypanel: "My dashboard",
    name: "Name",
    next: "Next",
    nextlevel: "Next level",
    nexts: "Nexts",
    no: "No",
    noActiveFormations: "No active courses available",
    noCheckTestAgain: "No, check test again",
    noDocs: "No documents available",
    noModules: "No modules available",
    noNews: "No updates available",
    noResults: "No results available",
    noTrainersAssigned: "No trainers assigned",
    notConclude: "Not Concluded",
    notStarted: "Not started",
    novities: "Novities",
    objectives: "Objectives",
    obligatorySatisfactionInq: "Satisfaction questionnaire required.",
    of: "of",
    oneToThree: "1 to 3",
    ongoing: "Ongoing",
    teamLeader: "Team Leader",
    toStart: "To Start",
    photo: "Photo",
    pointsEarned: "points",
    pointsForGamification: "Gamification Points",
    presential: "live / on-site",
    previous: "Previous",
    profile: "Profile",
    profileChanged: "Your data was changed",
    progress: "progress",
    realizationDate: "Realisation date",
    recents: "News",
    recipients: "Recipients",
    recover: "Recover",
    recoverPassword: "Recover Password?",
    recoverYourPassword: "Recover your Password",
    redoTest: "Redo test",
    registDate: "Date of Registration",
    registerCourse: "Course registration",
    registrations: "Registration",
    remember: "Remember",
    reproved: "Not approved",
    resgisteredIn: "Registered in",
    result: "Evaluation status",
    save: "Save",
    scheduledContent: "Scheduled Content",
    search: "Search",
    searchCourse: "Search Course...",
    searchGroup: "Search by Group...",
    searchStore: "Search by Store",
    selfControlInFoodSafety: "SELF-CONTROL IN FOOD SAFETY",
    serviceCertificationOnly:
      "SERVICE CERTIFICATION IN PF (FOR CERTIFIED STORES ONLY)",
    services: "Services",
    signin: "Login",
    signing: "Signing in",
    signout: "Signout",
    specificFormation: "Specific Training",
    startCourse: "Start course",
    startDate: "Start Date",
    startin: "start in",
    state: "State",
    store: "Store",
    submit: "Submit",
    success: "Success",
    successLogin: "Successful Login.",
    takesPlaceIn: "Takes place in",
    team: "team",
    technologicalEquipment: "Technological Equipment",
    terminated: "Terminated",
    textile: "Textile",
    thisMonth: "This month",
    threeToSix: "3 to 6",
    timeInMinutes: "Time lenght in minutes",
    today: "Today",
    topStore: "Top Store",
    total: "Total",
    totalUsers: "Total of users in the team",
    totalinscriptions: "Total inscriptions",
    trade: "Trade",
    trainers: "Trainers",
    trainingAction: "Training Action",
    transversalFormation: "Cross-sectional training",
    username: "Username",
    users: "Users",
    visualize: "Visualize",
    wait: "Waiting",
    week: "Week",
    welcome: "Welcome",
    with: "with",
    AvaliationGrid: "Avaliation Grid",
    withoutGrade: "Without attributed grade",
    yes: "Yes",
    yesIConfirm: "yes, i confirm",
    yourName: "Your name",
    zone: "Zone",
    classes: "Classes",
    password: "Password",
    confirmPassword: "Confirm Password",
    ForSafetyReasons:
      "For safety reasons it is necessary to change your password"
  }
};
