<template>
  <div id="WebPage" v-if="user">
    <app-header />
    <app-header-mobile />
    <transition name="fade" mode="out-in">
      <router-view id="Content" params="route: route" />
    </transition>
    <app-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import HeaderComponent from "@/components/layout/header";
import HeaderMobileComponent from "@/components/layout/HeaderMobile";
import FooterComponent from "@/components/layout/footer";

export default {
  components: {
    "app-header": HeaderComponent,
    "app-header-mobile": HeaderMobileComponent,
    "app-footer": FooterComponent
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    })
  }
};
</script>

<style></style>
