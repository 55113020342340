var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"bg-secondary d-flex align-items-center",attrs:{"id":"Header"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-6 col-12 text-sm-left text-center"},[_c('router-link',{attrs:{"to":"/dashboard","title":"logo"}},[_c('img',{staticClass:"mr-2 mr-lg-4",attrs:{"src":"/assets/imgs/logo.png","alt":"logo","title":"logo","height":"60"}})]),_c('app-dropdown',{attrs:{"classes":"btn btn-outline-light border op--light dropdown-toggle"}},[_c('template',{slot:"activator"},[_vm._v(_vm._s(_vm.$t("mypanel")))]),_c('div',{staticClass:"dropdown-menu__items"},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/dashboard"}},[_c('i',{staticClass:"icon-user mr-2 size--s18"}),_vm._v(" Meus cursos ")]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/catalog"}},[_c('i',{staticClass:"icon-book mr-2 size--s18"}),_vm._v(" Cursos ")]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/notices"}},[_c('i',{staticClass:"icon-adverts mr-2 size--s18"}),_vm._v(" Destaques ")])],1)],2)],1),_c('div',{staticClass:"col-sm-6 col-12 text-sm-right text-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('p',{staticClass:"mr-3"},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.user.Name))])]),_c('div',{staticClass:"drop-profile"},[_c('app-dropdown',{attrs:{"classes":"rounded--s50 p-0 border op--light d-flex align-items-center"}},[_c('template',{slot:"activator"},[_c('span',{staticClass:"profile circle"},[_c('img',{staticClass:"img-circle rounded img-fluid",attrs:{"src":_vm.GetRelativePath(
                        _vm.user.Photo ? _vm.user.Photo : '/Files/profile.png',
                        'w=45&h=45&mode=crop'
                      ),"alt":"foto user","title":"foto user"}})]),_c('i',{staticClass:"icon-arrow-down px-3"})]),_c('div',{staticClass:"dropdown-menu_details"},[_c('div',{staticClass:"d-flex align-items-center bg--primary p-4"},[_c('span',{staticClass:"circle"},[_c('img',{staticClass:"img-circle rounded img-fluid",attrs:{"src":`${_vm.GetRelativePath(
                          _vm.user.Photo ? _vm.user.Photo : '/Files/profile.png',
                          'w=90&h=90&mode=crop'
                        )}`,"alt":"foto user","title":"foto user"}})]),_c('div',{staticClass:"ml-3"},[_c('h4',{staticClass:"font--base text-white font-weight-normal"},[_vm._v(" "+_vm._s(_vm.user.Name)+" ")]),(_vm.user.Email)?_c('p',{staticClass:"size--s12"},[_vm._v(" "+_vm._s(_vm.user.Email)+" ")]):_vm._e(),_c('p',{staticClass:"size--s12"},[_vm._v(_vm._s(_vm.user.Code))])])]),_c('div',{staticClass:"dropdown-menu__items"},[_vm._l((_vm.userMenus),function(menu){return _c('router-link',{key:menu.title,staticClass:"dropdown-item",attrs:{"to":menu.route}},[_c('i',{staticClass:"size--s18 mr-2",class:menu.icon}),_vm._v(" "+_vm._s(menu.title)+" ")])}),(_vm.isTeamLeader)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/myaccount/team"}},[_c('i',{staticClass:"size--s18 mr-2 icon-users"}),_vm._v(" Equipa ")]):_vm._e(),(_vm.isAdmin && _vm.backendUrl)?_c('a',{staticClass:"dropdown-item",attrs:{"href":_vm.backendUrl,"target":"_blank"}},[_c('i',{staticClass:"size--s18 mr-2 icon-user"}),_vm._v(" Administração ")]):_vm._e(),_c('a',{staticClass:"btn btn-outline-light px-4 ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("signout")))])],2)])],2)],1),_c('router-link',{staticClass:"rounded-icon text-decoration-none btn btn-link p-0 border border-light ml-3",attrs:{"to":"/intro"}},[_c('i',{staticClass:"icon-help text-light",staticStyle:{"font-size":"27px"}})])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }