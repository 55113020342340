import "./styles/app.scss";

import Vue from "vue";
import Vuelidate from "vuelidate";
import VueI18n from "vue-i18n";
import VueSweetalert2 from "vue-sweetalert2";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css"; // only if your build system can import css, otherwise import it wherever you would import your css.
import axios from "axios";
import vSelect from "vue-select";
import store from "./store/global";
import router from "./router/router";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

import { messages } from "./i18n/messages";
import VueGtag from "vue-gtag";
import AppView from "./App";

Vue.config.productionTip = false;

// App register
axios.defaults.headers.common["APP"] = "FrontEnd";
axios.defaults.headers.get["Accepts"] = "application/json";

Vue.prototype.$http = axios;
Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(VuePlyr);
Vue.use(VueI18n);
Vue.component("v-select", vSelect);
Vue.use(VueGoodTablePlugin);

const i18n = new VueI18n({
  locale: "pt",
  messages
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-JKX7YJZH47" }
  },
  router
);

new Vue({
  i18n,
  router,
  store,
  render: h => h(AppView)
}).$mount("#app");
