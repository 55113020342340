import Vue from "vue";
import VueRouter from "vue-router";

import * as routesNames from "./config";
import * as types from "../store/types";

import LayoutView from "@/views/shared/layout";
import { DashboardView, IntroView } from "./modules/core";
import {
  CatalogIndexView,
  CoursesDetailDashboardView,
  CoursesDetailContentView,
  CoursesDetailSurveyView,
  CoursesDetailView,
  CoursesCourseAvaliationDetailView,
  CoursesAvaliationView
} from "./modules/catalog";
import {
  MyAccountDetailsView,
  MyAccountDownloadsView,
  MyAccountProfileView,
  MyAccountSecurityView,
  MyAccountDasboardView,
  MyAccountHistoryView,
  // MyAccountTeamView,
  MyAccountInscriptionsView,
  MyAccountCalendarView
} from "./modules/myaccount";
import { NoticesIndexView } from "./modules/notices";
import { SignInView, RecoverView } from "./modules/auth";
import {
  GamingIndexView,
  GamingTopsView,
  GamingActivitiesView
} from "./modules/gaming";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: LayoutView,
    beforeEnter(to, from, next) {
      if (window.localStorage.getItem(types.GLOBAL_USER_TOKEN)) {
        next();
      } else {
        next("/auth/signin");
      }
    },
    children: [
      {
        path: "",
        component: DashboardView
      },
      {
        name: routesNames.DASHBOARD_ROUTE,
        path: "/dashboard",
        component: DashboardView
      },
      {
        name: "intro",
        path: "/intro",
        component: IntroView
      },
      {
        path: "/notices",
        component: NoticesIndexView
      },
      {
        path: "/gamification",
        component: GamingIndexView,
        props: true,
        children: [
          {
            path: "",
            redirect: "tops"
          },
          {
            path: "tops",
            component: GamingTopsView
          },
          {
            path: "activities",
            component: GamingActivitiesView
          }
        ]
      },
      {
        path: "/catalog",
        component: CatalogIndexView,
        name: "catalog"
      },
      {
        path: "/courses/avaliation/:idCourseClass/course/:idCourse",
        component: CoursesCourseAvaliationDetailView,
        name: "course-class-avaliation"
      },
      {
        path:
          "/courses/avaliation/:idCourseClass/course/:idCourse/survey-avaliation/:idUser",
        component: CoursesAvaliationView,
        name: "course-class-avaliation"
      },

      {
        path: "/courses/detail/:idCourse",
        component: CoursesDetailView,
        props: true,
        children: [
          {
            path: "",
            redirect: "dashboard"
          },
          {
            path: "dashboard",
            component: CoursesDetailDashboardView
          },
          {
            path: "content/:idModule",
            component: CoursesDetailContentView
          },
          // {
          //   path: "survey-avaliation",
          //   component: CoursesDetailSurveyView,
          //   props: { isAvaliation: true, isFormation: false },
          // },
          {
            path: "survey-satisfaction",
            component: CoursesDetailSurveyView,
            props: { isAvaliation: false, isFormation: false }
          },
          {
            path: "survey-formation",
            component: CoursesDetailSurveyView,
            props: { isAvaliation: false, isFormation: true }
          }
        ]
      },
      {
        path: "/myaccount/",
        component: MyAccountDetailsView,
        props: true,
        children: [
          {
            path: "",
            redirect: "dashboard"
          },
          {
            path: "dashboard",
            component: MyAccountDasboardView
          },
          {
            path: "history",
            component: MyAccountHistoryView
          },
          {
            path: "profile",
            component: MyAccountProfileView
          },
          // {
          //   path: "team",
          //   component: MyAccountTeamView
          // },
          {
            path: "security",
            component: MyAccountSecurityView
          },
          {
            path: "calendar",
            component: MyAccountCalendarView
          },
          {
            path: "downloads",
            component: MyAccountDownloadsView
          },
          {
            path: "inscriptions",
            component: MyAccountInscriptionsView
          }
        ]
      }
    ]
  },
  {
    name: routesNames.LOGIN_ROUTE,
    path: "/auth/signin",
    component: SignInView,
    beforeEnter(to, from, next) {
      if (window.localStorage.getItem(types.GLOBAL_USER_TOKEN)) {
        next("/dashboard");
      } else {
        next();
      }
    }
  },
  {
    name: routesNames.RECOVER_ROUTE,
    path: "/auth/recover",
    component: RecoverView
  },
  {
    path: "*",
    redirect: "/dashboard"
  }
];

export default new VueRouter({
  mode: "history",
  routes,
  linkExactActiveClass: "exact-active"
});
