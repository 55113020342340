<template>
  <router-view v-if="init" />
</template>

<script>
import * as types from "@/store/types";
import { mapMutations } from "vuex";
import * as routeNames from "@/router/config";
import router from "@/router/router";
import authService from "@/services/api/authService";

export default {
  data() {
    return {
      init: false
    };
  },
  methods: {
    ...mapMutations({
      load: types.MUTATE_BASE,
      clear: types.MUTATE_CLEAR_STORE
    })
  },
  created() {
    const token = window.localStorage.getItem(types.GLOBAL_USER_TOKEN);
    if (!token && !location.pathname.startsWith("/auth/recover")) {
      this.init = true;
      this.clear();
      router.replace({ name: routeNames.LOGIN_ROUTE });
      return;
    } else if (!location.pathname.startsWith("/auth/recover")) {
      authService
        .post("/load", null, { headers: { Authorization: "Bearer " + token } })
        .then(res => {
          this.load(res.data);
        })
        .catch(() => {
          window.localStorage.removeItem(types.GLOBAL_USER_TOKEN);
          this.clear();
          router.replace({ name: routeNames.LOGIN_ROUTE });
        })
        .finally(() => (this.init = true));
    } else {
      this.init = true;
    }
  }
};
</script>
