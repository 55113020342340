import * as types from "./types";

export default {
  [types.GET_USER]: state => {
    return state.User;
  },
  [types.GET_POSITIONS]: state => {
    return state.Positions;
  },
  [types.GET_SUBSCRIPTIONS]: state => {
    return state.Subscriptions;
  },
  [types.GET_NOTICES]: state => {
    return state.Notices;
  },
  [types.GET_PROGRESSION_PROFILE]: state => {
    return state.ProgressionProfile;
  },
  [types.GET_FAVOURITES]: state => {
    return state.Favourites;
  },
  [types.GET_GAMIFICATION]: state => {
    return state.Gamification;
  }
};
