import * as types from "./types";
import * as routeNames from "@/router/config";
import router from "@/router/router";

import authService from "@/services/api/authService";

export default {
  [types.USER_LOGIN]: ({ commit }, payload) => {
    window.localStorage.setItem(types.GLOBAL_USER_TOKEN, payload.Token);
    commit(types.MUTATE_BASE, payload);
    router.replace({ name: routeNames.DASHBOARD_ROUTE });
  },
  [types.SUBSCRIPTION_STARTED]: ({ commit, state }, payload) => {
    const clone = [...state.Subscriptions];
    const index = clone.findIndex(
      s => s.IdUserCourseSubscription == payload.IdUserCourseSubscription
    );
    if (index >= 0) {
      clone[index].IsActive = true;
      clone[index].IsInitiated = true;
      clone[index].IdCurrentCourseModule = payload.IdCurrentCourseModule;
      commit(types.MUTATE_SUBSCRIPTIONS, clone);
    }
  },
  [types.SUBSCRIPTION_LOG]: ({ commit, state }, payload) => {
    const clone = [...state.Subscriptions];
    const index = clone.findIndex(
      s => s.IdUserCourseSubscription == payload.IdUserCourseSubscription
      );
      if (index >= 0) {
        clone[index].Minutes = payload.Minutes;
        clone[index].IdCurrentCourseModule = payload.IdCurrentCourseModule;

      commit(types.MUTATE_SUBSCRIPTIONS, clone);
    }
  },
  [types.USER_LOGOUT]: ({ commit }) => {
    const token = window.localStorage.getItem(types.GLOBAL_USER_TOKEN);
    authService
      .post("/logout", null, { headers: { Authorization: "Bearer " + token } })
      .then(() => {
        window.localStorage.removeItem(types.GLOBAL_USER_TOKEN);
        commit(types.MUTATE_CLEAR_STORE);
        router.replace({ name: routeNames.LOGIN_ROUTE });
      });
  }
};
