// Getters
export const GET_IS_AUTHENTICATED = "shared/GET_IS_AUTHENTICATED";
export const GET_USER = "shared/GET_USER";
export const GET_TOKEN = "shared/GET_TOKEN";
export const GET_NOTICES = "shared/GET_NOTICES";
export const GET_SUBSCRIPTIONS = "shared/GET_SUBSCRIPTIONS";
export const GET_POSITIONS = "shared/GET_POSITIONS";
export const GET_COURSES = "shared/GET_COURSES";
export const GET_PORTFOLIOS = "shared/GET_PORTFOLIOS";
export const GET_PROGRESSION_PROFILE = "shared/GET_PROGRESSION_PROFILE";
export const GET_GAMIFICATION = "shared/GET_GAMIFICATION";
export const GET_FAVOURITES = "shared/GET_FAVOURITES";

// Mutations
export const MUTATE_CLEAR_STORE = "shared/MUTATE_CLEAR_STORE";
export const MUTATE_USER = "shared/MUTATE_USER";
export const MUTATE_USER_NOTICES = "shared/MUTATE_USER_NOTICES";
export const MUTATE_USER_POSITIONS = "shared/MUTATE_USER_POSITIONS";
export const MUTATE_SUBSCRIPTIONS = "shared/MUTATE_SUBSCRIPTIONS";
export const MUTATE_PROGRESSION_PROFILE = "shared/MUTATE_PROGRESSION_PROFILE";
export const MUTATE_GAMIFICATION = "shared/MUTATE_GAMIFICATION";
export const MUTATE_FAVOURITES = "shared/MUTATE_FAVOURITES";
export const MUTATE_BASE = "shared/MUTATE_BASE";

// Actions
export const USER_LOGIN = "shared/USER_LOGIN";
export const USER_LOGOUT = "shared/USER_LOGOUT";
export const STORE_USER = "shared/STORE_USER";
export const SUBSCRIPTION_STARTED = "shared/SUBSCRIPTION_STARTED";
export const SUBSCRIPTION_LOG = "shared/SUBSCRIPTION_LOG";

export const GLOBAL_USER_TOKEN = "token-app";
